<template>
    <page-content title="加药记录">

        <common-table
                ref="table"
                path="web/iot/water/medicalrecord"
                date-range
                :queryParams="queryParams"
                :columns="columns">

            <template slot="search">
                <!--        <a-button  ghost type="primary" @click="add">新增</a-button>-->
            </template>

            <template slot="logo" slot-scope="{record}">
                <a-avatar shape="square" :size="30" :src="record.avatarUrl">{{record.nickName}}</a-avatar>
            </template>

            <template slot="operation" slot-scope="{record}">
                <action-view  @click="view(record)"></action-view>
            </template>

        </common-table>


    </page-content>
</template>
<script>
    export default {

        data () {
            return {
                queryParams:{
                    deviceId:this.$route.params.deviceId
                }
            }
        },

        computed: {
            columns () {
                return [
                    {
                        title: '项目名称',
                        dataIndex: 'deviceName',
                    },
                    {
                        title: '投药泵',
                        dataIndex: 'identifierName',
                    },
                    {
                        title: '开始时间',
                        dataIndex: 'createdAt',
                    },
                    {
                        title: '停止时间',
                        dataIndex: 'stopAt',
                    },
                    {
                        title: '加药时长',
                        dataIndex: 'durationStr',
                    },


                ]
            }
        },
        methods: {
            view (record) {
                this.$router.push('/device/camera/'+record.id)
            },

            getList () {
                this.$refs.table.getData()
            }

        }
    }
</script>
<style lang="less" scoped>

</style>
